<template>
  <div class="bg">
    <div class="container">
      <a href="mailto: fellpellerstudios@gmail.com">E-MAIL</a>
      <a href="https://www.facebook.com/fellpeller/">FACEBOOK</a>
      <a href="https://www.instagram.com/fellpeller/?hl=en">INSTAGRAM</a>
      <a href="https://twitter.com/fellpeller?lang=en">TWITTER</a>
      <a href="https://www.youtube.com/channel/UCQJBLTcKvTxPyYHWNLlPXcw">YOUTUBE</a>
    </div>
  </div>
</template>

<script>
export default {
    name: "home",
    data() {
    return {
      message: ''
    }
  }
}
</script>

<style scoped>
.bg {
    background-color: rgb(223, 223, 223);
    height: 100vh;
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
a {
    font-weight: bold;
    font-size: x-large;
    padding: 1vw;
    align-self: center;
    transition: all 1s ease;
}
</style>